.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.logo-margin .ant-avatar {
	margin-left: 42% !important;
}

.filter-label .ant-form-item-label {
	width: 100% !important;
	text-align: left;
}

.funds-header {
	background: #7cb305 !important;
}

.funds-card .ant-btn-primary {
	background: #7cb305 !important;
	border-color: #7cb305 !important;
}

.funds-card {
	border: 1px solid #5b8c00 !important;
	border-radius: 15px !important;
}

.stocks-table .ant-table-thead .ant-table-cell {
	border-top-left-radius: 2px !important;
	background-color: #001628 !important;
	color: white !important;
}
